import React, { useEffect, useState } from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import { getLocalisedPrice } from "../../../services/product/price"

const LineItemLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.22;
  color: ${colors.charcoal};
  font-family: sofia-pro,sans-serif;

  @media (max-width: ${breakpoints.md}) {
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
`

const LineItemRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: ${colors.greyDark};
  flex-direction: row;
  width: 280px;
  padding-right: 8px;

  @media (max-width: ${breakpoints.md}) {
    padding-right: 0;
  }
`

const OrderNumber = styled.span`
  width: 65px;
`

const Price = styled.div`
  ${fonts.garamondSemiBold}
  font-size: 18px;
  line-height: 1;
  letter-spacing: .48px;
  color: ${colors.charcoal};

  @media (max-width: ${breakpoints.md}) {
    font-size: 16px;
  }
`

const OrderStatus = styled.p`
  ${fonts.garamondSemiBold}
  font-size: 18px;
  text-decoration: underline;
  color: ${colors.charcoal};
  transition: color .25s cubic-bezier(.445,.05,.55,.95);

  :hover {
    color: ${colors.purple}
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 14px;
  }
`

const OrderDrawerLabel = (props) => {
  
  const order = props.order

  const date = new Date(order.node.processedAt)

  const year = date.getFullYear().toString()

  let dateString = `${date.getMonth()}-${date.getDate()}-${year.charAt(2)}${year.charAt(3)}`

  return (
    <>
      <LineItemLeft>
        <OrderNumber>{`#${order.node.orderNumber}`}</OrderNumber>
        {dateString}
      </LineItemLeft>
      <LineItemRight>
        <Price>{`${
          getLocalisedPrice(
            parseFloat(order.node.totalPrice.amount),
            order.node.totalPrice.currencyCode,
            false
          )}`}</Price>
        <OrderStatus>More info</OrderStatus>
      </LineItemRight>
    </>
  )
}

export default OrderDrawerLabel