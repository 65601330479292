const getUserErrorMessage = (shopifyErrorMessage) => {
  let userErrorMessage = ""
  switch (shopifyErrorMessage) {
    case "Resetting password limit exceeded. Please try again later.":

      userErrorMessage = "Please try again later."
      
      break;
    
    case "Creating Customer Limit exceeded. Please try again later.":
      
      userErrorMessage = "Please try again later."
      
      break;

    case "Argument 'password' on InputObject 'CustomerCreateInput' is required. Expected type String!":
      
      userErrorMessage = "Please enter a password."
      
      break;
    
    default:
      
      userErrorMessage = shopifyErrorMessage
      
      break;
  }
  return userErrorMessage
}

module.exports = {
  getUserErrorMessage
}