import React, { useEffect, useState } from "react"
import { getShopifyCustomerData } from "../../../services/shopify/queries"
import styled from "styled-components"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
// import ButtonDarkFullWidth from "../../Core/Buttons/ButtonDarkFullWidth"
import AddAddressForm from "./AddAddressForm"
import EditAddressForm from "./EditAddressForm"
import EditAccountForm from "./EditAccountForm"
import "../../../styles/accountTabs.css"
import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"
import { TextButtonStyle, ButtonStyleDarkFullWidth } from "../../Core/Buttons/Styles"
import axios from "axios"
import breakpoints from "../../../styles/breakpoints"
import OrderContent from "./OrderContent"
import OrderDrawerLabel from "./OrderDrawerLabel"
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import "../../../styles/ordersAccordion.css"

const LoggedInHeader = styled.div`
  width: 100%;
  padding: 175px 80px 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: ${colors.vanilla};

  @media (max-width: ${breakpoints.mdl}) {
    padding: 175px 40px 50px;
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 125px 30px 40px;
  }

  @media (max-width: 767px) {
    padding: 0px 30px 40px;
  }
`

const TabSummary = styled.div`
  max-width: 780px;
  margin: auto;
  margin-bottom: 80px;

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 60px;
  }
`

const Addresses = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`

const Address = styled.div`
  width: 33%;

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 30px;
    width: 100%;
  }
`

const LeaveArea = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`

const LogoutButton = styled.button`
  position: relative;
  transition: all .25s cubic-bezier(.215,.61,.355,1);
  padding: 0 0 7px;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${colors.charcoal};
  ${fonts.sofiaProBold}
  font-size: 12px;
  line-height: 1.07;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  color: ${colors.charcoal};
  margin-bottom: 30px;
  :hover {
    border-bottom: 1px solid ${colors.purple};
  }

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 11px;
    padding-bottom: 4px;
  }
`

const ButtonDarkFullWidth = styled.button`
  ${ButtonStyleDarkFullWidth}
`

const TextButton = styled.button`
  ${TextButtonStyle}
  border: none;
  padding: 0;
  background-color: transparent;
  display: block;
  margin-top: 20px;
`

const Eyebrow = styled.span`
  color: ${colors.charcoal};
  text-transform: uppercase;
  font-size: 16px;
  ${fonts.sofiaProBold}
  line-height: 1.29;

  @media (max-width: ${breakpoints.md}) {
    font-size: 13px;
    line-height: normal;
    letter-spacing: 1px;
  }
`

const DefaultEyebrow = styled.span`
  display: block;
  width: 100%;
  ${fonts.sofiaPro}
  margin-bottom: 11px;
  font-size: 12px;
  letter-spacing: normal;
  color: ${colors.greyExtraLight};
`

const Heading = styled.h1`
  color: ${colors.charcoal};
  margin-bottom: 0;
  margin-top: 20px;
  font-size: 40px;
  ${fonts.garamondRegular}
  line-height: normal;
  letter-spacing: normal;

  @media (max-width: ${breakpoints.md}) {
    font-size: 28px;
    margin-left: -1px;
    margin-bottom: -2px;
  }
`

const SubHeading = styled.h2`
  ${fonts.garamondRegular}
  font-size: 35px;
  padding-bottom: 22px;
  margin-bottom: 30px;
  line-height: .83;
  letter-spacing: normal;
  color: ${colors.charcoal};
  border-bottom: 1px solid ${colors.greyExtraLight};
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoints.md}) {
    font-size: 22px;
    letter-spacing: normal;
    padding-bottom: 16px;
    margin-bottom: 40px;
    border-bottom: 1px solid #e7e7e7;
  }
`

const FormSubHeading = styled.h2`
  ${fonts.garamondRegular}
  font-size: 35px;
  padding-bottom: 22px;
  margin-bottom: 52px;
  line-height: .83;
  letter-spacing: normal;
  color: ${colors.charcoal};
  border-bottom: 1px solid ${colors.greyExtraLight};
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoints.md}) {
    font-size: 22px;
    letter-spacing: normal;
    padding-bottom: 16px;
    margin-bottom: 40px;
    border-bottom: 1px solid #e7e7e7;
  }
`

const OrdersSubHeading = styled.h2`
  ${fonts.garamondRegular}
  font-size: 35px;
  padding-bottom: 22px;
  line-height: .83;
  letter-spacing: normal;
  color: ${colors.charcoal};
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoints.md}) {
    font-size: 22px;
    letter-spacing: normal;
    padding-bottom: 16px;
  }
`

const TextBlock = styled.p`
  display: block;
  font-size: 16px;
  ${fonts.sofiaPro}
  line-height: 1.78;
`

const AccountSection = () => {
  const [customer, setCustomer] = useState({})
  const [addressFormToShow, setAddressFormToShow] = useState("add")

  const getCustomer = async () => {
    const cat = window.localStorage.getItem("cat")
    if (cat) {
      const customerData = await getShopifyCustomerData(cat)

      if (!customerData || customerData.data.customer.customer === null) {
        removeLoginData();
        window.location.reload();

        return;
      }

      setCustomer(customerData.data.customer.customer)
    } else {
      removeLoginData();
      window.location.reload();

      return;
    }
  }

  useEffect(() => {
    getCustomer()
  }, [])

  const removeLoginData = () => {
    window.localStorage.removeItem("ili")
    window.localStorage.removeItem("cat")
    window.localStorage.removeItem("ttl")
  }

  const handleLogoutClick = () => {
    removeLoginData();

    window.location.href = `https://${process.env.GATSBY_SHOPIFY_SHOP_DOMAIN}/account/logout`
  }

  const handleEditDefaultAddressClick = () => {
    setAddressFormToShow("default")
  }

  const handleEditAddressClick = (id) => {
    setAddressFormToShow(id)
  }

  const handleDeleteAddressClick = async (id) => {
    const url = '/.netlify/functions/shopifyUpdate'

    const data = {
      "mutationType": "deleteAddress",
      "cat": window.localStorage.getItem("cat"),
      "data": {
        "id": id
      }
    }
    try {
      const response = await axios.post(url, data)
    } catch {

    }

    getCustomer()
  }

  const handleSubscriptionsClick = () => {
    if (typeof window !== "undefined") {
      const now = new Date()

      let loggedInExpiryDate = null

      if (typeof window.localStorage.getItem("ttl") !== null) {
        loggedInExpiryDate = new Date(window.localStorage.getItem("ttl"))
      }
      
      if (now > loggedInExpiryDate) {
        window.localStorage.removeItem("ili")
        window.localStorage.removeItem("cat")
        window.localStorage.removeItem("ttl")
        window.location.reload()
      } else {
        window.location.href = `https://${process.env.SHOPIFY_SHOP_DOMAIN}/a/account/login`
      }
    }
  }
  
  return (
    (typeof customer !== "undefined" && customer !== null) ?
    <div>
      <LoggedInHeader>
        <div>
          <Eyebrow>Hello,</Eyebrow>
          <Heading>{typeof customer.firstName !== "undefined" && customer.firstName}</Heading>
        </div>
        <LeaveArea>
          <LogoutButton onClick={handleLogoutClick}>Logout</LogoutButton>
          <ButtonDarkFullWidth onClick={handleSubscriptionsClick}>
            Manage Subscriptions
          </ButtonDarkFullWidth>
        </LeaveArea>
      </LoggedInHeader>
      <Tabs>
        <TabList>
          <Tab>Order History</Tab>
          <Tab>Account Details</Tab>
          <Tab>Addresses</Tab>
        </TabList>
        <TabPanel>
          <TabSummary>
          <OrdersSubHeading>Your orders</OrdersSubHeading>
            {(typeof customer.orders !== "undefined" && customer.orders.edges.length > 0) ?
              <Accordion allowZeroExpanded={true}>
              {customer.orders.edges.map((order, index) => {
                return (
                  <AccordionItem key={index}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <OrderDrawerLabel order={order} />
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <OrderContent order={order} />
                    </AccordionItemPanel>
                  </AccordionItem>
                )
              })}
              </Accordion>
              :
              <TextBlock style={{marginTop: "30px"}}>Currently no orders to display</TextBlock>
            }
          </TabSummary>
        </TabPanel>
        <TabPanel>
          <TabSummary>
            <SubHeading>Account</SubHeading>
            <TextBlock>
              {typeof customer.displayName !== "undefined" && customer.displayName}
              <br></br>
              {typeof customer.email !== "undefined" && customer.email}
            </TextBlock>
          </TabSummary>
          <TabSummary>
            <FormSubHeading>Edit Account</FormSubHeading>
            <EditAccountForm
              firstName={typeof customer.firstName !== "undefined" && customer.firstName}
              lastName={typeof customer.lastName !== "undefined" && customer.lastName}
              email={typeof customer.email !== "undefined" && customer.email}
              acceptsMarketing={typeof customer.acceptsMarketing !== "undefined" && customer.acceptsMarketing}
            />
          </TabSummary>
        </TabPanel>
        <TabPanel>
          <TabSummary>
            <SubHeading>Addresses</SubHeading>
            <Addresses>
              {(typeof customer.defaultAddress !== "undefined" && customer.defaultAddress) ?
                <Address>
                  <DefaultEyebrow>Default address</DefaultEyebrow>
                  <TextBlock>
                    {customer.displayName}
                  </TextBlock>
                  {
                    customer.defaultAddress.formatted.map((line, index) => {
                      return <TextBlock key={index}>{line}</TextBlock>
                    })
                  }
                  <TextButton onClick={handleEditDefaultAddressClick}>
                    Edit Address
                  </TextButton>
                  <TextButton onClick={() => handleDeleteAddressClick(customer.defaultAddress.id)}>
                    Delete Address
                  </TextButton>
                </Address>
                :
                <div display="hidden"></div>
              }
              {(typeof customer.addresses !== "undefined" && customer.addresses.edges.length > 1) ?
                customer.addresses.edges.map((address, index) => {
                  if (address.node.id !== customer.defaultAddress.id) {
                    return (
                      <Address>
                        <TextBlock>
                          {customer.displayName}
                        </TextBlock>
                        {
                          address.node.formatted.map((line, index) => {
                            return <TextBlock key={index}>{line}</TextBlock>
                          })
                        }
                        <TextButton onClick={() => handleEditAddressClick(address.node.id)}>
                          Edit Address
                        </TextButton>
                        <TextButton onClick={() => handleDeleteAddressClick(address.node.id)}>
                          Delete Address
                        </TextButton>
                      </Address>
                    )
                  }
                })
                :
                <div display="hidden"></div>
              }
            </Addresses>
          </TabSummary>
          {addressFormToShow === "add" &&
            <>
              <FormSubHeading>Add New Address</FormSubHeading>
              <AddAddressForm 
                defaultAddressExists={typeof customer.defaultAddress !== "undefined" && customer.defaultAddress} 
                firstName={typeof customer.firstName !== "undefined" && customer.firstName}
                lastName={typeof customer.lastName !== "undefined" && customer.lastName}
                update={getCustomer}
              />
            </>
          }
          {(typeof customer.defaultAddress !== "undefined" && customer.defaultAddress && addressFormToShow === "default") ?
            <>
              <FormSubHeading>Edit Address</FormSubHeading>
              <EditAddressForm
                isDefaultAddress={true}
                firstName={customer.defaultAddress.firstName}
                lastName={customer.defaultAddress.lastName}
                address1={customer.defaultAddress.address1}
                address2={customer.defaultAddress.address2}
                company={customer.defaultAddress.company}
                city={customer.defaultAddress.city}
                country={customer.defaultAddress.countryCodeV2}
                province={customer.defaultAddress.province}
                zip={customer.defaultAddress.zip}
                id={customer.defaultAddress.id}
                update={getCustomer}
              />
            </>
            :
            <div display="hidden"></div>
          }
          {(typeof customer.addresses !== "undefined" && customer.addresses.edges.length > 1) ?
            customer.addresses.edges.map((address, index) => {
              if (address.node.id !== customer.defaultAddress.id && addressFormToShow === address.node.id) {
                return (
                  <>
                    <FormSubHeading key={index}>Edit Address</FormSubHeading>
                    <EditAddressForm
                      isDefaultAddress={false}
                      firstName={address.node.firstName}
                      lastName={address.node.lastName}
                      address1={address.node.address1}
                      address2={address.node.address2}
                      company={address.node.company}
                      city={address.node.city}
                      country={address.node.countryCodeV2}
                      province={address.node.province}
                      zip={address.node.zip}
                      id={address.node.id}
                      update={getCustomer}
                    />
                  </>
                )
              }
            })
            :
            <div display="hidden"></div>
          }
        </TabPanel>
      </Tabs>
    </div>
    :
    <div></div>
  )
}

export default AccountSection
