import React from 'react'
import styled from "styled-components"

const StyledContainer = styled.div`

`

const ContentContainer = ({children}) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}

export default ContentContainer

