import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import ContentContainer from "../../components/Core/Containers/ContentContainer"
import AccountContentContainer from "../../components/Core/Containers/AccountContentContainer"
import LoginForm from "../../components/Accounts/Login/LoginForm"
import breakpoints from "../../styles/breakpoints"
import AccountSection from "../../components/Accounts/Account/AccountSection"

const LoginContainer = styled.div`
  padding: 0 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 58px);

  @media (min-width: ${breakpoints.md}) {
    margin-top: 40px;
    padding: 220px 0;
    min-height: 100vh;
  }
`

const LoggedInContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 58px);

  @media (min-width: ${breakpoints.md}) {
    margin-top: 40px;
    min-height: 100vh;
  }

  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`

const LoginPage = ({ location }) => {
  const path = location.pathname

  const [isLoggedIn, setIsLoggedIn] = useState(null)

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    if (window.localStorage.getItem("ili") !== "true") {
      setIsLoggedIn(false)
    } else {
      setIsLoggedIn(true)
    }
  }, [])

  if (isLoggedIn === null) {
    return (
      null
    )
  }

  return (
    (isLoggedIn) ?
    <Layout transparentHeader={true}>
      <Seo
        path={path}
        title={"Account"}
        description={"Jupiter account login"}
        fullTitle={false}
      />
      <AccountContentContainer>
        <LoggedInContainer>
          <AccountSection />
        </LoggedInContainer>
      </AccountContentContainer>
    </Layout>
    :
    <Layout transparentHeader={true}>
      <Seo
        path={path}
        title={"Account"}
        description={"Jupiter account login"}
        fullTitle={false}
      />
      <ContentContainer>
        <LoginContainer>
          <LoginForm />
        </LoginContainer>
      </ContentContainer>
    </Layout>
  )
}

export default LoginPage
