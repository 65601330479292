import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"

export const FormLabelStyle = `
  top: -22px;
  ${fonts.sofiaPro}
  font-size: 12px;
  color: ${colors.charcoal};
  margin-bottom: 10px;
  display: block;
  text-align: left;
  padding-left: 1px;
`

export const CheckboxLabelStyle = `
  top: 3px;
  left: 30px;
  ${fonts.sofiaPro}
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  text-align: left;
  padding-left: 1px;
  opacity: 1;
  :hover {
    ${fonts.sofiaProBold}
    color: ${colors.greyDark}
  }
`