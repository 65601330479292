import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import { InputContainerStyle, CheckboxContainerStyle } from "../Styles"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import axios from "axios"
import { FormLabelStyle, CheckboxLabelStyle } from "./Styles"
import countryList from "country-list"
import breakpoints from "../../../styles/breakpoints"
import { getUserErrorMessage } from "../../../services/core/accountFunctions"
import gsap from "gsap"

const FormContainer = styled.div`
  ${fonts.garamondRegular};
  max-width: 780px;
  margin: auto;
  width: 100%;
  margin-bottom: 80px;
`

const FormLabel = styled.label`
  ${FormLabelStyle}
`

const CheckboxLabel = styled.label`
  ${CheckboxLabelStyle}
`

const InputContainer = styled.div`
  ${InputContainerStyle}
  margin-bottom: 42px;
`

const InputContainerHalfWidth = styled.div`
  ${InputContainerStyle}
  width: calc(50% - 10px);
  margin-bottom: 42px;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`

const InputContainerGroup = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`

const CheckboxContainer = styled.div`
  ${CheckboxContainerStyle}
`

const SubmitButton = styled.button`
  ${ButtonStyleDark};
  max-width: unset;
`

const ServerError = styled.p`
  ${fonts.moderatRegular}
  font-size: 14px;
  letter-spacing: .25px;
  color: #c00;
  padding-top: 20px;
`

const EditAddressForm = (props) => {
  const [firstName, setFirstName] = useState(props.firstName)
  const [lastName, setLastName] = useState(props.lastName)
  const [address1, setAddress1] = useState(props.address1)
  const [address2, setAddress2] = useState(props.address2)
  const [company, setCompany] = useState(props.company)
  const [country, setCountry] = useState(props.country)
  const [city, setCity] = useState(props.city)
  const [province, setProvince] = useState(props.province)
  const [zip, setZip] = useState(props.zip)
  const [makeDefault, setMakeDefault] = useState(false)
  const [submitText, setSubmitText] = useState("Save")
  const [error, setError] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault()

    setSubmitText("Processing...")

    const id = props.id

    const url = '/.netlify/functions/shopifyUpdate'

    const data = {
      "mutationType": "updateAddress",
      "cat": window.localStorage.getItem("cat"),
      "data": {
        "firstName": firstName,
        "lastName": lastName,
        "address1": address1,
        "address2": address2,
        "company": company,
        "country": country,
        "city": city,
        "province": province,
        "zip": zip,
        "makeDefault": makeDefault,
        "id": id
      }
    }

    try {
      const response = await axios.post(url, data)

      if (response.data.response.customerAddressUpdate.customerUserErrors.length === 0) {
        setSubmitText("Success")
        props.update()
        gsap.delayedCall(1, () => { window.location.reload() })
      } else {
        const errorMessage = getUserErrorMessage(response.data.response.customerAddressUpdate.customerUserErrors[0].message)
        setError(errorMessage)
        setSubmitText("Save")
      }

    } catch {
      setError("Server error")
      setSubmitText("Save")
    }
  }

  let countriesData = countryList.getData()
  countriesData.sort((a, b) => {
    if (a.code === "US") {
      return -1
    }
    if (b.code === "US") {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <InputContainerGroup>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>First Name</FormLabel>
            <input 
              type="text" 
              name="address[firstName]" 
              value={props.firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </InputContainerHalfWidth>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>Last Name</FormLabel>
            <input 
              type="text" 
              name="address[lastName]" 
              value={props.lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </InputContainerHalfWidth>
        </InputContainerGroup>
        <InputContainer>
          <FormLabel style={{opacity: 1}}>Street Address</FormLabel>
          <input 
            type="text" 
            name="address[address1]"
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
          />
        </InputContainer>
        <InputContainerGroup>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>Apt./Suite</FormLabel>
            <input 
              type="text" 
              name="address[address2]"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </InputContainerHalfWidth>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>Company</FormLabel>
            <input 
              type="text" 
              name="address[company]"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </InputContainerHalfWidth>
        </InputContainerGroup>
        <InputContainerGroup>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>Country</FormLabel>
            <select onChange={(e) => setCountry(e.target.value)} defaultValue={country}>
              <option disabled>Select Country</option>
              {countriesData.map((countryData, index) => {
                return (
                  <option key={index} value={countryData.code}>{countryData.name}</option>
                )
              })}
            </select>
          </InputContainerHalfWidth>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>City</FormLabel>
            <input 
              type="text" 
              name="address[city]"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </InputContainerHalfWidth>
        </InputContainerGroup>
        <InputContainerGroup>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>State/Province</FormLabel>
            <input 
              type="text" 
              name="address[province]"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            />
          </InputContainerHalfWidth>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>Zip/Postal Code</FormLabel>
            <input 
              type="text" 
              name="address[zip]"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
          </InputContainerHalfWidth>
        </InputContainerGroup>
        {props.isDefaultAddress ?
        <div display="hidden"></div>
        :
        <CheckboxContainer>
          <input
            id="setAsDefault"
            type="checkbox" 
            name="address[default]"
            checked={makeDefault}
            onChange={() => setMakeDefault(!makeDefault)}
          />
          <CheckboxLabel htmlFor="setAsDefault" style={{opacity: 1}}>Set as default</CheckboxLabel>
        </CheckboxContainer>
        }
        <SubmitButton type={"submit"}>
          {submitText}
        </SubmitButton>
        <ServerError>{error}</ServerError>
      </form>
    </FormContainer>
  )
}

export default EditAddressForm