import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import { CheckboxContainerStyle, InputContainerStyle } from "../Styles"
import fonts from "../../../styles/fonts"
import axios from "axios"
import { FormLabelStyle, CheckboxLabelStyle } from "./Styles"
import breakpoints from "../../../styles/breakpoints"
import { getUserErrorMessage } from "../../../services/core/accountFunctions"
import gsap from "gsap"

const FormContainer = styled.div`
  ${fonts.garamondRegular};
  max-width: 780px;
  margin: auto;
  width: 100%;
  margin-bottom: 80px;
`

const FormLabel = styled.label`
  ${FormLabelStyle}
`

const CheckboxLabel = styled.label`
  ${CheckboxLabelStyle}
`

const InputContainer = styled.div`
  ${InputContainerStyle}
  margin-bottom: 42px;
`

const InputContainerHalfWidth = styled.div`
  ${InputContainerStyle}
  width: calc(50% - 10px);
  margin-bottom: 42px;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`

const InputContainerGroup = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`

const CheckboxContainer = styled.div`
  ${CheckboxContainerStyle}
`

const SubmitButton = styled.button`
  ${ButtonStyleDark};
  max-width: unset;
`

const ServerError = styled.p`
  ${fonts.moderatRegular}
  font-size: 14px;
  letter-spacing: .25px;
  color: #c00;
  padding-top: 20px;
`

const EditAccountForm = (props) => {
  const [firstName, setFirstName] = useState(props.firstName)
  const [lastName, setLastName] = useState(props.lastName)
  const [email, setEmail] = useState(props.email)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [acceptMarketing, setAcceptMarketing] = useState(props.acceptsMarketing)
  const [submitText, setSubmitText] = useState("Save")
  const [error, setError] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault()

    setSubmitText("Processing...")

    const url = '/.netlify/functions/shopifyUpdate'

    const confirmedPassword = password === confirmPassword ? password : false

    if (confirmedPassword) {
      const data = {
        "mutationType": "updateAccount",
        "cat": window.localStorage.getItem("cat"),
        "data": {
          "firstName": firstName,
          "lastName": lastName,
          "email": email,
          "password": confirmedPassword,
          "acceptsMarketing": acceptMarketing,
        }
      }

      try {
        const response = await axios.post(url, data)
  
        if (response.data.response.customerUpdate.customerUserErrors.length === 0) {
          setSubmitText("Success")
          gsap.delayedCall(1, () => { 
            window.localStorage.removeItem("ili")
            window.localStorage.removeItem("cat")
            window.localStorage.removeItem("ttl")
            window.location.href = `https://${process.env.GATSBY_SHOPIFY_SHOP_DOMAIN}/account/logout`
          })
        } else {
          const errorMessage = getUserErrorMessage(response.data.response.customerUpdate.customerUserErrors[0].message)
          setError(errorMessage)
          setSubmitText("Save")
        }

      } catch {
        setError("Server error")
        setSubmitText("Save")
      }

    } else if (confirmedPassword === "") {
      const data = {
        "mutationType": "updateAccount",
        "cat": window.localStorage.getItem("cat"),
        "data": {
          "firstName": firstName,
          "lastName": lastName,
          "email": email,
          "acceptsMarketing": acceptMarketing,
        }
      }

      try {
        const response = await axios.post(url, data)

        if (response.data.response.customerUpdate.customerUserErrors.length === 0) {
          setSubmitText("Success")
          gsap.delayedCall(1, () => { window.location.reload() })
        } else {
          const errorMessage = getUserErrorMessage(response.data.response.customerUpdate.customerUserErrors[0].message)
          setError(errorMessage)
          setSubmitText("Save")
        }

      } catch {
        setError("Server error")
        setSubmitText("Save")
      }
    } else {
      setError("Error: Passwords must match")
      setSubmitText("Save")
    }
  }

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <InputContainerGroup>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>First Name</FormLabel>
            <input 
              type="text" 
              name="customer[firstName]" 
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </InputContainerHalfWidth>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>Last Name</FormLabel>
            <input 
              type="text" 
              name="customer[lastName]" 
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </InputContainerHalfWidth>
        </InputContainerGroup>
        <InputContainer>
          <FormLabel style={{opacity: 1}}>Email</FormLabel>
          <input 
            type="email" 
            name="customer[email]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputContainer>
        <InputContainerGroup>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>Password</FormLabel>
            <input 
              type="password" 
              name="customer[password]"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputContainerHalfWidth>
          <InputContainerHalfWidth>
            <FormLabel style={{opacity: 1}}>Confirm Password</FormLabel>
            <input 
              type="password" 
              name="customer[confirmPassword]"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </InputContainerHalfWidth>
        </InputContainerGroup>
        {props.isDefaultAddress ?
        <div display="hidden"></div>
        :
        <CheckboxContainer>
          <input
            id="acceptMarketing"
            type="checkbox" 
            name="customer[acceptsMarketing]"
            checked={acceptMarketing ? true : false}
            onChange={() => setAcceptMarketing(!acceptMarketing)}
          />
          <CheckboxLabel htmlFor="acceptMarketing" style={{opacity: 1}}>Accept marketing</CheckboxLabel>
        </CheckboxContainer>
        }
        <SubmitButton type={"submit"}>
          {submitText}
        </SubmitButton>
        <ServerError>{error}</ServerError>
      </form>
    </FormContainer>
  )
}

export default EditAccountForm