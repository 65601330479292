import React, { useEffect, useState } from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import { getShopifyImage } from "../../../services/shopify/images"
import { getTotalDiscount } from "../../../services/shopify/discounts"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import { getLocalisedPrice } from "../../../services/product/price"

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  font-size: 14px;
  letter-spacing: -.29px;
  padding-bottom: 20px;
  color: ${colors.greyExtraLight};
  border-bottom: none;
  text-transform: none;
`

const LineItem = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
`

const LineItemLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LineItemRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  padding-right: 8px;
`

const Quantity = styled.div`
  margin-left: 20px;
  margin-right: 43px;
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 16px;
  ${fonts.sofiaPro}
  line-height: 1.22;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  color: ${colors.charcoal};
`

const Thumbnail = styled.img`
  display: block;
  width: auto;
  height: 170px;
`

const ProductTitle = styled.h4`
  font-size: 18px;
  ${fonts.garamondSemiBold};
  color: ${colors.charcoal};
`

const Price = styled.p`
  ${fonts.garamondRegular}
  font-size: 18px;
  line-height: 1;
  letter-spacing: .48px;
  color: ${colors.charcoal};
`

const BottomWapper = styled.div`
  justify-content: space-between;
  padding-bottom: 70px;
  border-top: 1px solid rgba(0,0,0,.1);
  display: flex;
  padding-top: 33px;
`

const ShippingAddress = styled.div`

`

const ShippingHeader = styled.h5`
  ${fonts.garamondSemiBold}
  font-size: 18px;
  color: ${colors.charcoal};
  margin-bottom: 11px;
`

const ShippingAddressInfo = styled.p`
  font-size: 16px;
  line-height: 1.78;
  color: ${colors.charcoal};
  ${fonts.sofiaPro}
`

const SummaryCheckout = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  color: ${colors.charcoal};
  align-self: flex-end;
`

const CheckoutItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const CheckoutText = styled.p`
  font-size: 16px;
  ${fonts.sofiaPro}
  line-height: 1.78;
  color: ${colors.charcoal};
`

const CheckoutDiscountText = styled.p`
  font-size: 16px;
  ${fonts.garamondSemiBold}
  line-height: 1.78;
  color: ${colors.orange};
`

const CheckoutTotalText = styled.p`
  font-size: 16px;
  ${fonts.garamondSemiBold}
  line-height: 1.78;
  color: ${colors.charcoal};
  letter-spacing: .48px;
`

const Button = styled.a`
  ${ButtonStyleDark}
  height: 50px;
  margin-top: 20px;
  width: 100%;
`

const OrderContent = (props) => {

  const order = props.order



  return (
    <>
      <Labels>
        <p>Description</p>
        <LineItemRight>
          <p>Quantity</p>
          <p>Price</p>
        </LineItemRight>
      </Labels>
      {order.node.lineItems.edges.map((item, index) => {
        return (
          <LineItem>
            <LineItemLeft>
              {item.node.variant ?
                <Thumbnail
                src={getShopifyImage(item.node.variant.image.src, "200x")}
                alt={item.node.variant.image.altText || ""}
                ></Thumbnail>
                :
                <Thumbnail></Thumbnail>
              }
              <ProductTitle>{item.node.title}</ProductTitle>
            </LineItemLeft>
            <LineItemRight>
              <Quantity><span>{item.node.quantity}</span></Quantity>
              {item.node.variant ? item.node.variant.price.amount ?
                <Price>{`$${(item.node.quantity * parseFloat(item.node.variant.price.amount)).toFixed(2)}`}</Price>
                :
                <Price>{`$${(item.node.quantity * parseFloat(item.node.variant.price)).toFixed(2)}`}</Price>
                :
                <Price></Price>
              }
            </LineItemRight>
          </LineItem>
        )
      })}
      <BottomWapper>
        <ShippingAddress>
          <ShippingHeader>Shipping</ShippingHeader>
          {order.node.shippingAddress ?
            <ShippingAddressInfo>{order.node.shippingAddress.name}</ShippingAddressInfo>
          :
            <ShippingAddressInfo></ShippingAddressInfo>
          }
          {order.node.shippingAddress ?
            order.node.shippingAddress.formatted.map((line, index) => {
              return <ShippingAddressInfo key={index}>{line}</ShippingAddressInfo>
            })
          :
            <ShippingAddressInfo></ShippingAddressInfo>
          }
        </ShippingAddress>
        <SummaryCheckout>
          {getTotalDiscount(order) > 0 ?
            <CheckoutItem>
              <CheckoutDiscountText>Discount</CheckoutDiscountText>
              <CheckoutDiscountText class="total">{`$${getTotalDiscount(order)}`}</CheckoutDiscountText>
            </CheckoutItem>
            :
            <CheckoutItem></CheckoutItem>
          }
          <CheckoutItem>
            <CheckoutText>Subtotal</CheckoutText>
            <CheckoutText>{`${
              getLocalisedPrice(
                parseFloat(order.node.subtotalPrice.amount),
                order.node.subtotalPrice.currencyCode,
                false
              )
              }`}</CheckoutText>
          </CheckoutItem>
          <CheckoutItem>
            <CheckoutText>Shipping</CheckoutText>
            <CheckoutText>{`${
              getLocalisedPrice(
                parseFloat(order.node.totalShippingPrice.amount),
                order.node.totalShippingPrice.currencyCode,
                false
              )
              }`}</CheckoutText>
          </CheckoutItem>
          <CheckoutItem>
            <CheckoutText>Tax</CheckoutText>
            <CheckoutText>{`${
              getLocalisedPrice(
                parseFloat(order.node.totalTax.amount),
                order.node.totalTax.currencyCode,
                false
              )
              }`}</CheckoutText>
          </CheckoutItem>
          <CheckoutItem>
            <CheckoutText>Total Price</CheckoutText>
            <CheckoutTotalText>{`${
              getLocalisedPrice(
                parseFloat(order.node.totalPrice.amount),
                order.node.totalPrice.currencyCode,
                false
              )
              }`}</CheckoutTotalText>
          </CheckoutItem>
          <Button href={order.node.statusUrl}>
            View details
          </Button>
        </SummaryCheckout>
      </BottomWapper>
    </>
  )
}

export default OrderContent
